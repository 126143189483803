// http-client.js
import axios from "axios";
import { getToken } from "../token-interceptor";
import { BASE_URL } from "../config";

// Create an Axios instance
const protectedClient = axios.create({
  baseURL: `${BASE_URL}/private`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Middleware to add tokens to requests
protectedClient.interceptors.request.use(
  async (config) => {
    const authToken = await getToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`; // Attach the access token
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Export a public client that does not require a token
export const PublicHttpClient = axios.create({
  baseURL: `${BASE_URL}/public`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Export the axios instance as a ProtectedHttpClient for endpoints that require a token
export const ProtectedHttpClient = protectedClient;
