import React, { useState, useEffect } from "react";
import "./bulk-hours-upload-popup.scss";
import { store } from "../../../redux/store";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import ManageEventHelper from "../../helpers/manage-events-helper";
import Papa from "papaparse"; // Library to parse CSV files
import { uploadBulkHours } from "../../../http-calls";

const BulkHoursUploadPopup = ({
  isVisible = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [years, setYears] = useState([]);
  const [events, setEvents] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [isEmailCertificates, setIsEmailCertificates] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvError, setCsvError] = useState("");
  const [apiResponse, setApiResponse] = useState(null);

  // Fetch events and categorize them
  const fetchAllEvents = async () => {
    store.dispatch(showLoader("Loading events..."));
    try {
      const { data } = await ManageEventHelper.getAllEventsWithSlots();
      const formattedEvents = ManageEventHelper.categorisedEvents(data);
      console.log(JSON.stringify(formattedEvents));
      setYears(formattedEvents.map((item) => item.year)); // Extract years
      setEvents(formattedEvents); // Store all formatted events
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
    store.dispatch(hideLoader("Loading events..."));
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setSelectedEvent("");
    setSelectedSlot("");
    setSlots([]);
  };

  const handleEventChange = (e) => {
    const eventId = e.target.value;
    setSelectedEvent(eventId);
    const yearData = events.find((item) => item.year === selectedYear);
    const selectedEventObj = yearData?.events.find(
      (event) => event.event_id === eventId
    );
    setSlots(selectedEventObj?.slots || []);
    setSelectedSlot("");
  };

  const handleSlotChange = (e) => {
    setSelectedSlot(e.target.value);
  };

  const downloadSampleCsv = () => {
    const sampleData = [
      ["email", "hours", "notes"],
      ["user1@example.com", 5, "Worked on project A"],
      ["user2@example.com", 3, "Assisted in event B"],
      ["user3@example.com", 4, "Completed task C"],
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      sampleData.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample_bulk_hours.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Cleanup
  };

  const handleSubmit = async () => {
    if (!csvData.length || !selectedSlot) {
      setCsvError(
        "Please upload a valid CSV and select a slot before submission."
      );
      return;
    }

    const payload = {
      event_slot_id: selectedSlot,
      email_cert: isEmailCertificates,
      data: csvData.map(({ email, hours }) => ({
        email,
        hours: parseInt(hours), // Ensure hours is parsed as a number
      })),
    };

    try {
      store.dispatch(showLoader("Submitting data..."));
      const { data } = await uploadBulkHours(payload); // Call the API function
      setApiResponse(data); // Store the API response
      alert("Uploaded bulk hours, please check the report for details.");
      setCsvData([]); // Clear CSV data on successful submission
      onSubmit(data); // Pass the result to the parent component, if needed
    } catch (error) {
      setCsvError(error.message || "Something went wrong during submission.");
    } finally {
      store.dispatch(hideLoader());
    }
  };

  const downloadReport = () => {
    if (!apiResponse) {
      alert("No report available to download.");
      return;
    }

    const { response } = apiResponse;
    const reportData = [
      ["Email", "Hours", "Status", "Error"], // Header row
      ...response.map((item) => [
        item.email,
        item.hours,
        item.status,
        item.error || "None",
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      reportData.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "bulk_hours_report.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Cleanup
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data, errors } = results;

        // Check for errors in parsing
        if (errors.length > 0) {
          setCsvError("Invalid CSV format.");
          setCsvData([]);
          return;
        }

        // Validate required fields
        const isValid = data.every(
          (row) =>
            row.email &&
            row.hours &&
            !isNaN(Number(row.hours)) && // Ensure hours is a number
            row.notes
        );

        if (!isValid) {
          setCsvError(
            "CSV validation failed. Ensure all rows have email, hours (numeric), and notes."
          );
          setCsvData([]);
          return;
        }

        // If valid, update state
        setCsvError("");
        setCsvData(data);
      },
      error: () => {
        setCsvError("Failed to process the CSV file.");
        setCsvData([]);
      },
    });
  };

  if (!isVisible) return null;

  return (
    <div className="bulkHoursUploadPopup show">
      <div className="overlay" onClick={onCancel}></div>

      <div className="modalContainer">
        <div className="modalHeader">
          <h3 className="mb-0">
            <b>Upload Bulk Hours</b>
          </h3>
          <span className="close" onClick={onCancel}>
            <i className="fa fa-times"></i>
          </span>
        </div>

        <div className="modalContent">
          {/* Year Dropdown */}
          <div className="form-group">
            <label>Select Year</label>
            <select
              className="form-control"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="" disabled>
                Select a year
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {`${parseInt(year) - 1} - ${year}`}
                </option>
              ))}
            </select>
          </div>

          {/* Event Dropdown */}
          {selectedYear && (
            <div className="form-group">
              <label>Select Event</label>
              <select
                className="form-control"
                value={selectedEvent}
                onChange={handleEventChange}
              >
                <option value="" disabled>
                  Select an event
                </option>
                {events
                  .find((item) => item.year === selectedYear)
                  ?.events.map((event) => (
                    <option key={event.event_id} value={event.event_id}>
                      {event.event_name}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {/* Slot Dropdown */}
          {selectedEvent && (
            <div className="form-group">
              <label>Select Slot</label>
              <select
                className="form-control"
                value={selectedSlot}
                onChange={handleSlotChange}
              >
                <option value="" disabled>
                  Select a slot
                </option>
                {slots.map((slot) => (
                  <option key={slot.event_slot_id} value={slot.event_slot_id}>
                    {`${slot.start_date} ${slot.start_time} - ${slot.end_time}`}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Additional Inputs */}
          <div className="form-group">
            <button className="btn btn-secondary" onClick={downloadSampleCsv}>
              Download Sample CSV
            </button>
          </div>

          <div className="form-group">
            <label>Upload File</label>
            <input
              type="file"
              className="form-control"
              accept=".csv"
              onChange={handleFileUpload}
            />
            {csvError && <div className="text-danger mt-2">{csvError}</div>}
            {csvData.length > 0 && (
              <div className="text-success mt-2">
                {csvData.length} rows successfully loaded.
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={isEmailCertificates}
                onChange={() => setIsEmailCertificates(!isEmailCertificates)}
              />{" "}
              Email Certificates
            </label>
          </div>
        </div>
        {apiResponse && (
          <div className="form-group mt-3">
            <button className="btn btn-secondary" onClick={downloadReport}>
              Download Report
            </button>
          </div>
        )}

        <div className="modalFooter text-end mt-4">
          <button className="btn btn-secondary me-2" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!selectedYear || !selectedEvent || !selectedSlot}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkHoursUploadPopup;
