import Axios from "axios";
import { BASE_URL } from "../config/index";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../http-connectors";

export const createUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/public/create-user`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createUser call error: ", e);
        reject(e);
      });
  });
};

export const tokenExchange = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/public/exchange-token`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("tokenExchange call error: ", e);
        reject(e);
      });
  });
};

export const getAllUpcomingEvents = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-all-events`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllUpcomingEvents call error: ", e);
        reject(e);
      });
  });
};

export const getAllRegisteredEvents = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-registered-events`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllRegisteredEvents call error: ", e);
        reject(e);
      });
  });
};

export const getAllFocus = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-all-focus`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllFocus call error: ", e);
        reject(e);
      });
  });
};

export const eventRegistration = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/event-registration`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createUser call error: ", e);
        reject(e);
      });
  });
};

export const cancelEventRegistration = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/private/cancel-event-registration`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createUser call error: ", e);
        reject(e);
      });
  });
};

export const getProfileData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-profile`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getProfileData call error: ", e);
        reject(e);
      });
  });
};

export const updateProfileData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/update-profile`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateProfileData call error: ", e);
        reject(e);
      });
  });
};

export const sendRegistrationConfirmationMail = async (payload) => {
  console.log("payload :>> ", payload);
  await Axios.post(
    `https://prod-letconnectapi.azurewebsites.net/api/email?code=XsoJY4IdT7YMFWF1eceTvFdD/asPkUBGAaS08oK4QupTwuYWkFAXzA==&clientId=letsconnect-prod`,
    payload
  );
};

export const getAllRegistrations = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/public/get-all-registrations`, false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllRegistrations call error: ", e);
        reject(e);
      });
  });
};

export const checkAdminAccess = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/public/check-admin-access`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("checkAdminAccess call error: ", e);
        reject(e);
      });
  });
};

export const deleteRegistration = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/delete-registration`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllRegistrations call error: ", e);
        reject(e);
      });
  });
};

export const addNewSponsor = (sponsor) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/add-sponsor`, true, sponsor)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addNewSponsor call error: ", e);
        reject(e);
      });
  });
};

export const editSponsor = (sponsor) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/edit-sponsor`, true, sponsor)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("editSponsor call error: ", e);
        reject(e);
      });
  });
};

export const deleteSponsor = (sponsor) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/delete-sponsor`, true, sponsor)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteSponsor call error: ", e);
        reject(e);
      });
  });
};

export const updateUserInDB = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/update-user`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateUserInDB call error: ", e);
        reject(e);
      });
  });
};

export const addNewSpeaker = (speaker) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/add-speaker`, true, speaker)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addNewSpeaker call error: ", e);
        reject(e);
      });
  });
};

export const editSpeaker = (speaker) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/edit-speaker`, true, speaker)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("editSpeaker call error: ", e);
        reject(e);
      });
  });
};

export const deleteSpeaker = (speaker) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/delete-speaker`, true, speaker)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteSpeaker call error: ", e);
        reject(e);
      });
  });
};

export const cloneSpeaker = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/clone-speaker`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("cloneSpeaker call error: ", e);
        reject(e);
      });
  });
};

export const toggleFeaturedSponsor = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/private/mark-as-featured-sponsor`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("toggleFeaturedSponsor call error: ", e);
        reject(e);
      });
  });
};

export const generateCertificate = ({ registrationId, hours }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/private/generate-registration-certificate`,
      true,
      { registrationId, hours }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("cloneSpeaker call error: ", e);
        reject(e);
      });
  });
};

export const sendCertEmailApi = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/send-cert-email`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("sendCertEmailApi call error: ", e);
        reject(e);
      });
  });
};

export const updateTimeslot = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/update-timeslot`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateTimeslot call error: ", e);
        reject(e);
      });
  });
};

export const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-all-users`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllUsers call error: ", e);
        reject(e);
      });
  });
};

export const registerUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/register-to-events`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllUsers call error: ", e);
        reject(e);
      });
  });
};

export const updateUserByAdmin = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/update-user-by-admin`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllUsers call error: ", e);
        reject(e);
      });
  });
};

export const addEvent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/events`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addEvent call error: ", e);
        reject(e);
      });
  });
};

export const updateEvent = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/private/events`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateEvent call error: ", e);
        reject(e);
      });
  });
};

export const deleteEvent = (payload) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/private/events`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteEvent call error: ", e);
        reject(e);
      });
  });
};

export const addEventSlot = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/events-slots`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addEventSlot call error: ", e);
        reject(e);
      });
  });
};

export const updateEventSlot = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/private/events-slots`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateEventSlot call error: ", e);
        reject(e);
      });
  });
};

export const deleteEventSlot = (payload) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/private/events-slots`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteEventSlot call error: ", e);
        reject(e);
      });
  });
};

export const getAllEventsWithSlots = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/private/get-events-with-slots`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllEventsWithSlots call error: ", e);
        reject(e);
      });
  });
};

export const uploadFile = (payload) => {
  return Axios.post(`${BASE_URL}/upload`, payload);
};

export const getUserRegistrations = (userId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${BASE_URL}/public/get-user-events?user_id=${userId}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getUserRegistrations call error: ", e);
        reject(e);
      });
  });
};

export const uploadBulkHours = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/private/bulk-hours-import`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("uploadBulkHours call error: ", e);
        reject(e);
      });
  });
};