import React, { useState, useEffect } from "react";
import axios from "axios";
import "./slot-detail-modal.scss";
import { BASE_URL } from "../../../config";
import { getToken } from "../../../token-interceptor";

const SlotDetailModal = ({ slotDetails = {}, onClose = () => {}, event }) => {
  const [loading, setLoading] = useState(true);
  const [slotStats, setSlotStats] = useState(null);
  const [webinarId, setWebinarId] = useState("");
  const [confirmationView, setConfirmationView] = useState(null);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [useEmailAsName, setUseEmailAsName] = useState(false);

  useEffect(() => {
    const fetchSlotStats = async () => {
      try {
        const authToken = await getToken();
        const headers = {};

        if (authToken) {
          headers["Authorization"] = "Bearer " + authToken;
        }

        const response = await axios.get(
          `${BASE_URL}/private/webinar-stats?event_slot_id=${slotDetails.event_slot_id}`,
          { headers }
        );
        if (!response.data.error) {
          setSlotStats(response.data.data);
          setWebinarId(response.data.data.webinar_id);
        }
      } catch (error) {
        console.error("Error fetching slot stats:", error);
      } finally {
        setLoading(false);
      }
    };

    if (slotDetails.event_slot_id) {
      fetchSlotStats();
    }
  }, [slotDetails.event_slot_id]);

  const handleUpdateWebinarId = async () => {
    try {
      setLoading(true);
      const authToken = await getToken();
      const headers = {};

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      const payload = {
        ...slotDetails,
        webinar_id: webinarId,
      };

      await axios.put(`${BASE_URL}/private/events-slots`, payload, { headers });
      alert("Webinar ID updated successfully!");
    } catch (error) {
      console.error("Error updating webinar ID:", error);
      alert("Failed to update Webinar ID. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSyncAll = async (useEmailAsName) => {
    if (confirmationView !== "sync") {
      setConfirmationView("sync");
      setConfirmationInput("");
      return;
    }

    if (confirmationInput !== "SYNC") {
      alert("Please type 'SYNC' to confirm.");
      return;
    }

    try {
      setLoading(true);
      const authToken = await getToken();
      const headers = {};

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      const payload = {
        event_slot_id: slotDetails.event_slot_id,
        use_email_as_name: useEmailAsName, // Add the flag
      };

      await axios.post(`${BASE_URL}/private/sync-webinar`, payload, {
        headers,
      });
      alert("Webinar synced successfully!");
      setConfirmationView(null);
      setConfirmationInput("");
    } catch (error) {
      console.error("Error syncing webinar:", error);
      alert("Failed to sync webinar. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelinkAll = async () => {
    if (confirmationView !== "delink") {
      setConfirmationView("delink");
      setConfirmationInput("");
      return;
    }

    if (confirmationInput !== "DELINK") {
      alert("Please type 'DELINK' to confirm.");
      return;
    }

    try {
      setLoading(true);
      const authToken = await getToken();
      const headers = {};

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      const payload = {
        event_slot_id: slotDetails.event_slot_id,
      };

      await axios.post(`${BASE_URL}/private/delink-webinar`, payload, {
        headers,
      });
      alert("Webinar delinked successfully!");
      setConfirmationView(null);
      setConfirmationInput("");
    } catch (error) {
      console.error("Error delinking webinar:", error);
      alert("Failed to delink webinar. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="slotDetailModal show">
        <div className="overlay" onClick={onClose}></div>
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="mb-0">
              <b>Event Slot Editor</b>
            </h3>
            <span className="close" onClick={onClose}>
              <i className="fa fa-times"></i>
            </span>
          </div>
          <div className="modalContent">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  if (confirmationView) {
    return (
      <div className="slotDetailModal show">
        <div
          className="overlay"
          onClick={() => {
            setConfirmationView(null);
            setConfirmationInput("");
            setUseEmailAsName(false); // Reset checkbox
          }}
        ></div>
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="mb-0">
              <b>Confirmation Required</b>
            </h3>
            <span
              className="close"
              onClick={() => {
                setConfirmationView(null);
                setConfirmationInput("");
                setUseEmailAsName(false); // Reset checkbox
              }}
            >
              <i className="fa fa-times"></i>
            </span>
          </div>
          <div className="modalContent">
            <p>
              Are you sure you want to{" "}
              {confirmationView === "sync" ? "sync" : "delink"} all webinars?
              This action is irreversible.
            </p>
            {confirmationView === "sync" && (
              <div className="form-check mt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="useEmailAsName"
                  checked={useEmailAsName}
                  onChange={(e) => setUseEmailAsName(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="useEmailAsName">
                  Use email prefix as name for missing names
                </label>
              </div>
            )}
            <input
              type="text"
              className="form-control mt-3"
              placeholder={`Type '${confirmationView.toUpperCase()}' to confirm`}
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
            />
          </div>
          <div className="modalFooter d-flex justify-content-end mt-4">
            <button
              className="btn btn-secondary me-2"
              onClick={() => {
                setConfirmationView(null);
                setConfirmationInput("");
                setUseEmailAsName(false); // Reset checkbox
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (confirmationView === "sync") {
                  handleSyncAll(useEmailAsName); // Pass the flag
                } else {
                  handleDelinkAll();
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="slotDetailModal show">
      <div className="overlay" onClick={onClose}></div>

      <div className="modalContainer">
        <div className="modalHeader">
          <h3 className="mb-0">
            <b>Event Slot Editor</b>
          </h3>
          <span className="close" onClick={onClose}>
            <i className="fa fa-times"></i>
          </span>
        </div>

        <div className="modalContent">
          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mb-1 me-2">Event Name:</label>
            <span className="form-value">{event.event_name || ""}</span>
          </div>

          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mb-1 me-2">Start Time:</label>
            <span className="form-value">{slotDetails.start_time || ""}</span>
          </div>

          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mb-1 me-2">End Time:</label>
            <span className="form-value">{slotDetails.end_time || ""}</span>
          </div>

          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mb-1 me-2">Total Registrations:</label>
            <span className="form-value">
              {slotStats ? slotStats.total_registrations : 0}
            </span>
          </div>

          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mb-1 me-2">Total with Join URL:</label>
            <span className="form-value">
              {slotStats ? slotStats.total_with_join_url : 0}
            </span>
          </div>

          <div className="form-group mb-3 d-flex align-items-center">
            <label className="mb-1 me-2">Webinar ID:</label>
            <input
              type="text"
              className="form-control me-2 flex-grow-1"
              placeholder="Enter Webinar ID"
              value={webinarId}
              onChange={(e) => setWebinarId(e.target.value)}
            />
            <button
              className="btn btn-primary btn-sm"
              onClick={handleUpdateWebinarId}
            >
              Update
            </button>
          </div>

          <div className="modalFooter d-flex justify-content-between mt-4">
            <button className="btn btn-secondary" onClick={onClose}>
              Back
            </button>
            <div>
              {slotDetails?.webinar_id?.length ? (
                <button
                  className="btn btn-success me-2"
                  onClick={handleSyncAll}
                >
                  Sync All
                </button>
              ) : (
                <></>
              )}
              <button className="btn btn-danger" onClick={handleDelinkAll}>
                Delink All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotDetailModal;
