import { PublicHttpClient } from "./rest-client";

const AuthApi = {
  public: {
    checkIfEmailExists: (email) =>
      PublicHttpClient.get(`/check-email-exists?email=${email}`),
  },
};

export default AuthApi;
